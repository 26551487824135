import { memo } from "react";

export const FourOhFour = memo(() => {

    return (
        <div >
           {("not found")} 😥
        </div>
    );
});
